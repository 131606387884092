import React from "react";
const Notifications = () => {
  return (
    <div
      className="px-1 py-0  mb-0 text-center text-uppercase"
      style={{
        // borderTopColor: "#065c84",
        // borderBottomColor: "#e5cb05",
        borderRadius: "0px",
        height: "28px",
        background:"#065c84"
      }}
    >
      <marquee behavior="" direction="">
        <strong style={{ color: "white" }}>
          We actively encourage women to apply for the open job positions
        </strong>
      </marquee>
    </div>
  );
};

export default Notifications;
