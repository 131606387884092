import { Divider, IconButton, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const ApplicationGuidelines = ({ expanded }) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  return (
    <section
      className="card elevated rounded border mt-4 bg-light"
      aria-labelledby="application-guidelines-header"
    >
      <div
        style={{
          cursor: "pointer",
        }}
        onClick={() => setIsExpanded(!isExpanded)}
        className="card-header rounded-top bg-info2 text-light text-uppercase"
        id="application-guidelines"
        // role="button"
      >
        <span className="d-flex justify-content-between align-items-center">
          <Typography
            variant="h6"
            className="font-weight-light"
            id="application-guidelines-header"
          >
            Application Guidelines
          </Typography>
          <IconButton
            size="small"
            color="inherit"
            aria-expanded={isExpanded}
            aria-label={
              isExpanded
                ? "show less application guidelines"
                : "show more application guidelines"
            }
          >
            {!isExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </span>
      </div>

      <div className={`card-body ${!isExpanded ? "d-none" : ""}`}>
        <Typography gutterBottom variant="p" className="text-blue-100">
         1. Registration
        </Typography>
        <ul>
          <li className="font-weight-light" style={{ fontSize: "14px" }}>
            Create an account to access this application
          </li>
        </ul>

        {/* Login */}
        <Typography gutterBottom variant="p" className="text-blue-100">
          2. Login
        </Typography>
        <ul>
          <li className="font-weight-light" style={{ fontSize: "14px" }}>
            Login into the system
          </li>
        </ul>

        {/* Complete your profile */}
        <Typography gutterBottom variant="p" className="text-blue-100">
          3. Complete your profile
        </Typography>
        <ul>
          {/* <li>A complete profile will make it easier to apply for jobs</li> */}
          <li className="font-weight-light" style={{ fontSize: "14px" }}>
            Check your profile and update accordingly.
          </li>
          <li className="font-weight-light" style={{ fontSize: "14px" }}>
            Fill in all the necessary information in the profile section.
            (Higher Education, Languages and References are mandatory fields).
          </li>
        </ul>

        {/* Apply for a job */}
        <Typography gutterBottom variant="p" className="text-blue-100">
          4. Apply for a job
        </Typography>
        <ul>
          <li className="font-weight-light" style={{ fontSize: "14px" }}>
            Read carefully the Job description, Job Profile and Skills required
            for the respective positions before proceeding to apply.
          </li>
          <li className="font-weight-light" style={{ fontSize: "14px" }}>
            Apply for a job by clicking on the <strong>Apply Now</strong>{" "}
            button.
          </li>

          <li className="font-weight-light" style={{ fontSize: "14px" }}>
            Once shortlisting completed, you will receive an email notification
            informing you of your application status.
          </li>
        </ul>
        <Divider />
        <div
          className="text-black font-weight-bold text-left mt-3"
          style={{ fontSize: "13px" }}
        >
          For any support, contact our support team at:
          <br />
          <p className="mt-2">
            <a className="text-blue-100 btn-link" href={`tel:9997`}>
              Call us: <strong>9997</strong>
            </a>

            <br />
            <a
              className="text-blue-100 btn-link"
              href={`https://web.whatsapp.com/send?phone=0785569363&text=How%20May%20I%20help%20You?`}
              target="_blank"
            >
              Text us: <strong>0785569363</strong> (via WhatsApp for recruitment
              inquiries)
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default ApplicationGuidelines;
